import store from '@/store'

export const useSalaries = () => {

  const submitValidatedSalary = (salary) => {
    return new Promise((resolve, reject) => {
      if ('id' in salary && salary.id != null) {
        updateSalary(salary)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewSalary(salary)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewSalary = (salary) => {
    return new Promise((resolve, reject) => {
      store.dispatch('salary2/addSalary', salary)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateSalary = (salary) => {
    return new Promise((resolve, reject) => {
      store.dispatch('salary2/updateSalary', salary)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const recognizeSalary = (salaryDocument) => {
    return new Promise((resolve, reject) => {
      store.dispatch('document/recognizeDocument', [salaryDocument, 'salary'])
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadSalary = (salary) => {
    let link = document.createElement('a')
    link.href = salary.paySlip.url
    link.target = '_blank'
    link.download = salary.paySlip.name + salary.paySlip.extension
    link.click()
  }

  const removeSalary = (salary) => {
    return new Promise((resolve, reject) => {
      store.dispatch('salary/removeSalary', salary)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return {
    submitValidatedSalary,
    recognizeSalary,
    downloadSalary,
    removeSalary,
  }
}