<template>
  <div>

    <base-template
        title="Salaries"
        itemView="list"
        :text-button-add="$t('Add')"
        :columns="columns"
        :filters="filters"
        :items="salaries2"
        :context="salariesContext"
        :isActionColumnEnabled="true"
        :open-view-window.sync="openViewWindow"
        :allow-add="allowAdd"
        :allow-add-by-upload="allowAddByUpload"
        :allow-add-payment="true"
        :is-button-add-payment-disabled="!selectedItems.length"
        :display-custom-fields="displayCustomFields&&(allowAdd||allowAddByUpload)"
        selectable="multi"

        @fetch="$emit('reloadSalaries', $event)"
        @rowSelected="rowSelected"
        @selectItem="showDetails"
        @addItem="salariesModalShow=true"
        @addItemByUpload="salariesByUploadModalShow = true"
        @addPayment="addPayment(selectedItems)"
    >
      <template #bottomTitle>
        <i v-if="salariesContext.custom_fields && displayCustomFields && !allowAdd && !allowAddByUpload">
          {{ currency(salariesContext.custom_fields.excludingExpensesAndReimbursements) }}
          {{ $t('excludingExpensesAndReimbursements') }}
        </i>
      </template>

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <salary-card
            :salary="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-payable="allowPayment"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="showDetails(item)"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="downloadSalary(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Payment-->
          <button-payment
              @click.native.stop="addPayment([item])"
              v-b-tooltip.hover.left="capitalize($t('addPayment'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              :disabled="item._balance == 0"
              size="sm"
          />

          <!--          Edit -->
          <button-edit
              v-if="allowEdit"
              @click.native.stop="editSalary(item)"
              v-b-tooltip.hover.left="$t('Edit')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Delete-->
          <button-remove
              v-if="allowRemove"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              @click.native.stop="deleteSalaryAlert(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>


      <!--  =========================== -->
      <!--  ===     Select view     === -->
      <!--  =========================== -->
      <template #leftViewWindow="{item}">
        <salary-card
            :salary="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-selected="item.id==selectedItems[0].id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template
          #windowView
          v-if="selectedItems.length"
      >
        <div class="d-flex justify-content-between mb-1">
          <h4>{{ selectedItems[0]._display }}</h4>

          <div>
            <!--          Download-->
            <button-download
                @click.native.stop="downloadSalary(selectedItems[0])"
                v-b-tooltip.hover.left="$t('Download')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Edit -->
            <button-edit
                v-if="allowEdit"
                @click.native.stop="editSalary(selectedItems[0])"
                v-b-tooltip.hover.left="$t('Edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Close-->
            <button-close
                @click.native.stop="showDetails(null)"
                v-b-tooltip.hover.left="$t('Close')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />
          </div>
        </div>

        <!--        <iframe-->
        <!--            :src="selectedItems[0].paySlip.url"-->
        <!--            width="100%"-->
        <!--            height="1192px"-->
        <!--        />-->

        <salary-view
            :salary="selectedItems[0]"
        />


      </template>
    </base-template>

    <!-- modal salary by upload-->
    <modal-upload-salary
        :singleFile.sync="newSalaryFile"
        :documentType="'salary'"
        :title="capitalize($t('salary'))"
        :isOpen.sync="salariesByUploadModalShow"

    />

    <!-- modal salaries-->
    <modal-salaries
        :salaries.sync="currentSalaries"
        :title="salaryModalTitle"
        :isOpen.sync="salariesModalShow"
        @submitValidated="submitValidatedSalariesLocal(currentSalaries)"
    />

    <modal-payment
        :payment.sync="currentPayment"
        :title="'id' in currentPayment?$t('EditPayment'):$t('NewPayment')"
        :isOpen.sync="paymentModalShow"

        @submitValidated="submitValidatedPaymentLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../utils/filter'
import { useSalaries }          from '../../views/humanResources/salaries2/useSalaries'
import { usePayments }          from '../../views/accounting2/usePayment'

import BaseTemplate      from '../base/Base'
import SalaryCard        from '../card/Salary2.vue'
import ButtonView        from '../button/View'
import ButtonDownload    from '../button/Download'
import ButtonEdit        from '../button/Edit.vue'
import ButtonRemove      from '../button/Remove.vue'
import ButtonClose       from '../button/Close'
import SalaryView        from './SalaryView.vue'
import ModalUploadSalary from '../prompt/Upload'
import ModalSalaries     from '../prompt/Salaries'
import ModalPayment      from '../../components/prompt/Payment2'
import ButtonPayment     from '../../components/button/Payment'

import i18n   from '../../libs/i18n'
import useAPI from '../../utils/useAPI'
import store  from '../../store'
import moment from 'moment'

export default {
  components: {
    BaseTemplate,
    SalaryCard,
    ButtonView,
    ButtonDownload,
    ButtonEdit,
    ButtonRemove,
    ButtonClose,
    SalaryView,
    ModalUploadSalary,
    ModalSalaries,
    ModalPayment,
    ButtonPayment,
  },
  props: {
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: true
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    allowRemove: {
      type: Boolean,
      default: true
    },
    allowPayment: {
      type: Boolean,
      default: true
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    },
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.t('month')),
        key: 'month',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.tc('employee')),
        key: 'employee',
        sortable: true,
        cell: {
          useDisplay: true
        }
      },
      {
        display: capitalize(i18n.t('paidByEmployer')),
        key: 'paidByEmployer',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
      {
        display: capitalize(i18n.t('net')),
        key: 'net',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
      {
        display: capitalize(i18n.t('paidVacationRemaining')),
        key: 'paidVacationRemaining',
        sortable: true,
        cell: {}
      },
      {
        display: capitalize(i18n.t('luncheonVoucherQuantity')),
        key: 'luncheonVoucherQuantity',
        sortable: true,
        cell: {}
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: false,
        cell: {
          translate: true,
          useBadge: true,
          type: 'paymentStatus',
          // center: true
        }
      },

    ])
    const filters = ref([])
    const openViewWindow = ref(false)
    const selectedItems = ref([])
    const newSalaryFile = ref(null)
    const salariesByUploadModalShow = ref(false)
    const currentSalaries = ref([])
    const salaryModalTitle = ref(i18n.t('NewSalary'))
    const salariesModalShow = ref(false)
    const currentPayment = ref({})
    const paymentModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { salaries2, salariesContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(newSalaryFile, (val) => {
      if (val != null) {
        recognizeSalary(val)
            .then(response => {
              // console.log(response.data)
              salariesByUploadModalShow.value = false
              currentSalaries.value = response.data
              newSalaryFile.value = null
              salariesModalShow.value = true

              let i = 0
              currentSalaries.value.forEach(s => {
                s._uuid = i
                i++
              })

            })
      }

    })

    watch(salariesModalShow, val => {
      if (val == false) {
        currentSalaries.value = []
        salaryModalTitle.value = i18n.t('NewSalary')
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      recognizeSalary,
      downloadSalary,
      removeSalary,
      submitValidatedSalary
    } = useSalaries()

    const { submitValidatedPayment } = usePayments()

    const showDetails = (salary) => {
      if (salary != null) {
        downloadSalary(salary)

        // store.dispatch('salary2/getSalary', salary.id)
        //     .then(response => {
        //       selectedItems.value = [salary]
        //       selectedItems.value[0] = response
        //       openViewWindow.value = true
        //     })

      } else {
        selectedItems.value = []
        openViewWindow.value = false
      }
    }

    const editSalary = (salary) => {
      salaryModalTitle.value = i18n.t('EditSalary')

      store.dispatch('salary2/getSalary', salary.id)
          .then(salary => {
            currentSalaries.value = [salary]
            salariesModalShow.value = true
          })
    }

    const submitValidatedSalariesLocal = (salaries) => {
      console.log(JSON.parse(JSON.stringify(salaries)))
      salaries.forEach(salary => {
        submitValidatedSalary(salary)
            .then(response => {
              salariesModalShow.value = false
              resetSalary()
            })
      })
    }

    const resetSalary = () => {
      currentSalaries.value = JSON.parse(JSON.stringify([]))
    }

    const rowSelected = (rowSelected) => {
      selectedItems.value = rowSelected
    }

    const addPayment = (salaries) => {
      if (salaries.length) {
        let amount = 0
        let paymentRelations = []
        let salariesDisplays = []

        salaries.forEach(s => {
          amount = parseFloat(s._balance).toFixed(2)
          paymentRelations.push({
            amount: parseFloat(s._balance).toFixed(2),
            salary: s
          })

          if (!salariesDisplays.includes(s.employee._display)) {
            salariesDisplays.push(s.employee._display + ' : ' + moment(s.month).format('MMMM YYYY'))
          }
        })

        currentPayment.value.paymentMethod = { id: 1, name: i18n.t('Bank transfert') }
        currentPayment.value.paymentRelations = paymentRelations
        currentPayment.value.reference = salariesDisplays.join(', ')

        paymentModalShow.value = true
      }
    }

    const submitValidatedPaymentLocal = () => {
      submitValidatedPayment(currentPayment.value)
          .then(response => {
            console.log(response)
            resetPayment()
            selectedItems.value = []
            openViewWindow.value = false
            paymentModalShow.value = false
          })
    }

    const resetPayment = () => {
      currentPayment.value = JSON.parse(JSON.stringify(store.getters['payment/getEmptyPayment']))
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetSalary()
    resetPayment()

    return {
      // Components
      capitalize,
      currency,

      // Data
      columns,
      filters,
      openViewWindow,
      selectedItems,
      salariesModalShow,
      salariesByUploadModalShow,
      newSalaryFile,
      currentSalaries,
      salaryModalTitle,
      currentPayment,
      paymentModalShow,

      // Computed
      salaries2,
      salariesContext,

      // Methods
      downloadSalary,
      removeSalary,
      showDetails,
      editSalary,
      submitValidatedSalariesLocal,
      rowSelected,
      addPayment,
      submitValidatedPaymentLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteSalaryAlert (salary) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theSalary') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeSalary(salary)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>