<template>
  <div>
    <b-row>

      <!--    Date-->
      <b-col md="12">
        <field-date
            name="date"
            :isRequired="true"
            :model.sync="payment.date"
        />
      </b-col>

      <!--    Payment method-->
      <b-col md="12">
        <field-select
            :model.sync="payment.paymentMethod"
            name="paymentMethod"
            :options="autocompletePaymentMethods"
            :isRequired="true"
            label="name"
        />
      </b-col>

      <!-- Bank account -->
      <b-col md="8">
        <field-select
            :model.sync="payment.bankAccount"
            name="bankAccount"
            :options="autocompleteBankAccounts"
            :isRequired="true"
            label="_display"
        />
      </b-col>

      <!-- Amount -->
      <b-col
          md="4"
      >
<!--        <field-input-->
<!--            v-if="'paymentRelations' in payment && payment.paymentRelations.length == 1"-->
<!--            name="amount"-->
<!--            :rules="getPaymentAmountRule()"-->
<!--            :model.sync="payment.paymentRelations[0].amount"-->
<!--        />-->
        <field-input
            v-if="'paymentRelations' in payment && payment.paymentRelations.length == 1"
            name="amount"
            :model.sync="payment.paymentRelations[0].amount"
        />
        <field-input
            v-else-if="isEmptyPaymentCreation"
            name="amount"
            :model.sync="payment.amount"
        />
        <b-form-group
            v-else
            :label="capitalize($t('amount'))"
        >
          {{ currency(amountLocal) }}
        </b-form-group>

      </b-col>

      <!-- Reference -->
      <b-col cols="12">
        <field-input
            name="reference"
            :model.sync="payment.reference"
        />
      </b-col>

      <!-- Attachment -->
      <b-col cols="12">
        <field-file
            name="attachment"
            :display-label="true"
            :singleFile.sync="payment.attachment"
            :accepted-types="['pdf', 'image']"
            :document-type="'paymentAttachment'"
        />
      </b-col>

    </b-row>

    <div v-if="displayDetailsLocal">
      <b-row
          v-for="(pr, index) in payment.paymentRelations"
          :key="index"
      >
        <b-col md="4">
          <b-form-group
              v-if="'outgoingInvoice' in pr && pr.outgoingInvoice != null"
              :label="capitalize($tc('invoice',1))"
          >
            {{ pr.outgoingInvoice.billNumber }}
          </b-form-group>
          <b-form-group
              v-else-if="'incomingInvoice' in pr && pr.incomingInvoice != null"
              :label="capitalize($tc('invoice',1))"
          >
            {{ pr.incomingInvoice.billNumber }}
          </b-form-group>
          <b-form-group
              v-else-if="'vatDeclaration' in pr && pr.vatDeclaration != null"
              :label="capitalize($tc('vatDeclaration',1))"
          >
            {{ pr.vatDeclaration.month|moment('LL') }}
          </b-form-group>
          <b-form-group
              v-else-if="'socialSecurityContribution' in pr && pr.socialSecurityContribution != null"
              :label="capitalize($tc('socialSecurityContribution',1))"
          >
            {{ pr.socialSecurityContribution.month|moment('LL') }}
          </b-form-group>
          <b-form-group
              v-else-if="'salary' in pr && pr.salary != null"
              :label="capitalize($tc('salary',1))"
          >
            {{ pr.salary.employee._display }} {{ pr.salary.month|moment('MMMM YYYY') }}
          </b-form-group>

        </b-col>
        <b-col md="4">
          <b-form-group
              v-if="'outgoingInvoice' in pr && pr.outgoingInvoice != null"
              :label="capitalize($t('balance'))"
          >
            <!--            {{ currency(pr.outgoingInvoice._balance) }}-->
            {{ currency(getBalance(pr, index)) }}
          </b-form-group>
          <b-form-group
              v-else-if="'incomingInvoice' in pr && pr.incomingInvoice != null"
              :label="capitalize($t('balance'))"
          >
            <!--            {{ currency(pr.incomingInvoice._balance) }}-->
            {{ currency(getBalance(pr, index)) }}
          </b-form-group>
          <b-form-group
              v-else-if="'vatDeclaration' in pr && pr.vatDeclaration != null"
              :label="capitalize($t('balance'))"
          >
            <!--            {{ currency(pr.incomingInvoice._balance) }}-->
            {{ currency(getBalance(pr, index)) }}
          </b-form-group>
          <b-form-group
              v-else-if="'socialSecurityContribution' in pr && pr.socialSecurityContribution != null"
              :label="capitalize($t('balance'))"
          >
            {{ currency(getBalance(pr, index)) }}
          </b-form-group>
          <b-form-group
              v-else-if="'salary' in pr && pr.salary != null"
              :label="capitalize($t('balance'))"
          >
            {{ currency(getBalance(pr, index)) }}
          </b-form-group>
        </b-col>
        <b-col md="4">
          <field-input
              v-if="'outgoingInvoice' in pr && pr.outgoingInvoice != null"
              name="amount"
              :rules=outgoingInvoiceRule(pr.outgoingInvoice)
              :model.sync="pr.amount"
          />
          <field-input
              v-else-if="'incomingInvoice' in pr && pr.incomingInvoice != null"
              name="amount"
              :rules=incomingInvoiceRule(pr.incomingInvoice)
              :model.sync="pr.amount"
          />
          <field-input
              v-else-if="'vatDeclaration' in pr && pr.vatDeclaration != null"
              name="amount"
              :rules=vatDeclarationRule(pr.vatDeclaration)
              :model.sync="pr.amount"
          />
          <field-input
              v-else-if="'socialSecurityContribution' in pr && pr.socialSecurityContribution != null"
              name="amount"
              :rules=chargeRule(pr.socialSecurityContribution)
              :model.sync="pr.amount"
          />
          <field-input
              v-else-if="'salary' in pr && pr.salary != null"
              name="amount"
              :rules=salaryRule(pr.salary)
              :model.sync="pr.amount"
          />
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../utils/filter'

import useAPI from '../../utils/useAPI'
import FieldDate from '../input/Date'
import FieldSelect from '../input/Select2'
import FieldInput from '../input/Input'
import FieldFile from '../input/File'

export default {
  components: {
    FieldDate,
    FieldSelect,
    FieldInput,
    FieldFile
  },
  props: {
    payment: {
      type: Object,
      default: () => {}
    },
    maxAmount: {
      type: Number,
      default: null
    },
    displayDetails: {
      type: Boolean,
      default: false,
    },
    isEmptyPaymentCreation: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    console.log(props.payment)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const displayDetailsLocal0 = ref(props.displayDetails)
    const initialPayment = ref(JSON.parse(JSON.stringify(props.payment)))

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompletePaymentMethods, autocompleteBankAccounts } = useAPI()

    const displayDetailsLocal = computed(() => {
      return displayDetailsLocal0.value || ('paymentRelations' in props.payment && props.payment.paymentRelations.length > 1)
    })

    const amountLocal = computed(() => {
      let amountLocal = 0

      if ('paymentRelations' in props.payment) {
        props.payment.paymentRelations.forEach(pr => amountLocal += parseFloat(pr.amount ? pr.amount : 0))
      }

      return amountLocal
    })

    const isPaymentEditing = computed(() => {
      return 'id' in props.payment
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.displayDetails, val => {
      displayDetailsLocal0.value = val
    })

    // watch(() => props.payment, val => {
    //   console.log(val)
    // }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const incomingInvoiceRule = (incomingInvoice) => {
      // console.log(incomingInvoice)
      return 'required|decimal'


      // if (isPaymentEditing.value) {
      //   return 'required|decimal|positive'
      // } else {
      //   return 'required|decimal|positive|max_value:' + parseFloat(incomingInvoice._balance)
      // }
    }

    const outgoingInvoiceRule = (outgoingInvoice) => {
      return 'required|decimal'
      // if (isPaymentEditing.value) {
      //   return 'required|decimal|positive'
      // } else {
      //   return 'required|decimal|positive|max_value:' + parseFloat(outgoingInvoice._balance)
      // }
    }

    const vatDeclarationRule = (vatDeclaration) => {
      // if (isPaymentEditing.value) {
      return 'required|decimal'
      // } else {
      //   return 'required|decimal|positive|max_value:' + parseFloat(outgoingInvoice._balance)
      // }
    }

    const chargeRule = (charge) => {
      // if (isPaymentEditing.value) {
      return 'required|decimal'
      // } else {
      //   return 'required|decimal|positive|max_value:' + parseFloat(outgoingInvoice._balance)
      // }
    }

    const salaryRule = (salary) => {
      return 'required|decimal'
    }

    const getBalance = (paymentRelation, index) => {
      if (initialPayment.value.paymentRelations[index].incomingInvoice != null) {
        let initialBalance = parseFloat(initialPayment.value.paymentRelations[index].incomingInvoice._balance) + parseFloat(initialPayment.value.paymentRelations[index].amount)

        return initialBalance - parseFloat(paymentRelation.amount ? paymentRelation.amount : 0)
      } else if (initialPayment.value.paymentRelations[index].outgoingInvoice != null) {
        let initialBalance = parseFloat(initialPayment.value.paymentRelations[index].outgoingInvoice._balance) + parseFloat(initialPayment.value.paymentRelations[index].amount)

        return initialBalance - parseFloat(paymentRelation.amount ? paymentRelation.amount : 0)
      } else if (initialPayment.value.paymentRelations[index].vatDeclaration != null) {
        let initialBalance = parseFloat(initialPayment.value.paymentRelations[index].vatDeclaration._balance) + parseFloat(initialPayment.value.paymentRelations[index].amount)

        return initialBalance - parseFloat(paymentRelation.amount ? paymentRelation.amount : 0)
      } else if (initialPayment.value.paymentRelations[index].socialSecurityContribution != null) {
        let initialBalance = parseFloat(initialPayment.value.paymentRelations[index].socialSecurityContribution._balance) + parseFloat(initialPayment.value.paymentRelations[index].amount)

        return initialBalance - parseFloat(paymentRelation.amount ? paymentRelation.amount : 0)
      } else if (initialPayment.value.paymentRelations[index].salary != null) {
        let initialBalance = parseFloat(initialPayment.value.paymentRelations[index].salary._balance) + parseFloat(initialPayment.value.paymentRelations[index].amount)

        return initialBalance - parseFloat(paymentRelation.amount ? paymentRelation.amount : 0)
      } else {
        return 0
      }
    }

    const getPaymentAmountRule = () => {
      console.log("B", props.maxAmount)
      console.log(initialPayment.value)
      if ('paymentRelations' in props.payment) {
        let maxAmount = JSON.parse(JSON.stringify(props.maxAmount))

        if (props.maxAmount != null) {
          maxAmount = parseFloat(maxAmount)
        }

        props.payment.paymentRelations.forEach((pr, i) => {
          if (pr.incomingInvoice != null) {
            maxAmount += parseFloat(initialPayment.value.paymentRelations[i].incomingInvoice._balance) + parseFloat(initialPayment.value.paymentRelations[i].amount)
          }
          if (pr.outgoingInvoice != null) {
            maxAmount += parseFloat(initialPayment.value.paymentRelations[i].outgoingInvoice._balance) + parseFloat(initialPayment.value.paymentRelations[i].amount)
          }
        })
        return 'required|decimal' + (maxAmount != null ? ('|max_value:' + parseFloat(maxAmount)) : '')
      } else {
        return 'required|decimal'
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data
      // componentKey,

      // Computed
      autocompletePaymentMethods,
      autocompleteBankAccounts,
      displayDetailsLocal,
      amountLocal,
      isPaymentEditing,

      // Methods
      incomingInvoiceRule,
      outgoingInvoiceRule,
      vatDeclarationRule,
      getBalance,
      getPaymentAmountRule,
      chargeRule,
      salaryRule,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>